





















import { mapMutations, mapState } from 'vuex';
import { RootMutations } from '@/store/types/mutation-types';
import mixins from 'vue-typed-mixins';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';

export default mixins(AccountLayoutMixin).extend({
  name: 'OTPRequireDialog',

  computed: {
    ...mapState({
      otpRequireDialog: (state: any) => state.otpRequireDialog,
    }),
    otpRequireDialogModel: {
      get (): boolean {
        return this.otpRequireDialog;
      },
      set (value: boolean) {
        this.changeOtpRequireDialog(value);
      }
    }
  },

  methods: {
    ...mapMutations({
      changeOtpRequireDialog: RootMutations.CHANGE_OTP_REQUIRE_DIALOG
    }),
    pushToAddAuthenticator () {
      this.changeOtpRequireDialog(false);
      this.$router.push(this.getAccountRoute({ name: 'user-security' }));
    }
  }
})
