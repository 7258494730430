<template>
  <v-app id="slick-cactus-app" :class="[{ 'ov-hidden': isLoading }, {'has-nav': !$store.state.nav}, {'devmode': $devmode}]">
    <div class="notification-wrapper">
      <AppNotification
        v-for="(item, key, index) in notifications"
        :key="key"
        :index="index"
        :notification-id="key"
        :notification="item"
      />
    </div>
    <SplashScreen v-if="isLoading" />
    <AccountWrapper v-else />
    <v-dialog
      width="400"
      :value="isOutlookExpired && !outlookProcessed"
      persistent
    >
      <MemberOutlookExpired
        @outlook:processed="outlookProcessed = true"
      />
    </v-dialog>
    <v-snackbar
      v-model="snackbar.open"
      color="info"
      :bottom="true"
      :timeout="-1"
      class="app-snackbar"
    >
      <div v-html="snackbar.message" />
      <v-btn dark text @click="closeSnackbarHandler"> Close </v-btn>
    </v-snackbar>
    <v-dialog v-model="dialog" fullscreen>
      <TrackerWrapper />
    </v-dialog>
    <v-dialog
      :key="`${showSignIn}-sign-in`"
      v-model="showSignIn"
      width="600"
    >
      <SignInCard
        :prevent-redirect="preventRedirect"
        allow-close
        @success="successEventHandler"
        @close="showSignIn = false"
      />
    </v-dialog>
    <v-dialog
      v-model="emailVerifyDialog"
      width="400"
    >
      <v-card class="pt-4">
        <v-card-text class="text-center">
          <v-icon x-large>mdi-email-seal-outline</v-icon>
          <div class="title">Email Confirmed</div>
          <p class="subtitle-2">Your email address has been successfully confirmed</p>
          <v-btn @click="emailVerifyDialog = false">Close</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <OTPRequireDialog v-if="user && !user.otp_enabled" />
  </v-app>
</template>

<script>
import { RootActions } from '@/store/types/action-types';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { eventBus } from './eventbus/eventbus';
import { TOGGLE_SPLASH_SCREEN, TOGGLE_SNACKBAR } from './store/action-types';
import { GET_LISTS } from './store/modules/admin/admin-types';
import { GET_USER_SETTINGS } from '@/store/modules/settings/settings-actions-types';
import { subscribeToNotifications } from '@/utils/firebase';
import { SubscriptionsActions } from '@/store/modules/subscriptions/subscription-types';
import { authService } from '@/bundles/Auth/factory/authServiceFactory';
import { MapStylesMixin } from '@/bundles/Map/mixins/MapStylesMixin';
import { defaultMapStyle } from './bundles/Map/helpers/defaultMapStyle';
import { getLocaleMessages } from './bundles/Localization/messages';
import { parseJwt } from '@/bundles/App/helpers/parseJWT';
import { RootMutations } from '@/store/types/mutation-types';
import { initSocket } from '@/plugins/socket/socket';

import SplashScreen from './components/SplashScreen';
import TrackerWrapper from './components/common-components/tracker/TrackerWrapper';
import SignInCard from '@/components/SignInCard';
import AppNotification from '@/bundles/App/components/AppNotification.vue';
import AccountWrapper from '@/bundles/App/components/AccountWrapper';
import MemberOutlookExpired from '@/bundles/Members/components/MemberOutlookExpired.vue';
import OTPRequireDialog from '@/bundles/User/components/OTPRequireDialog.vue';

export default {
  name: 'App',

  components: {
    OTPRequireDialog,
    AccountWrapper,
    SplashScreen,
    SignInCard,
    TrackerWrapper,
    AppNotification,
    MemberOutlookExpired,
  },

  mixins: [MapStylesMixin],

  data: () => ({
    dialog: false,
    feedbackInfo: null,
    isUpdateAppWindowShown: false,
    showSignIn: false,
    preventRedirect: false,
    swUpdate: {
      snackbar: false,
      refreshing: false,
      registration: null,
    },
    outlookProcessed: false,
    emailVerifyDialog: false,
  }),

  computed: {
    ...mapState({
      user: (state) => state.user,
      token: (state) => state.idToken,
      userId: (state) => state.userId,
      isMultipleAccountAuth: (state) => state.isMultipleAccountAuth,
      notifications: (state) => state.Notifications.notifications,
      isLoading: (state) => state.splash,
      snackbar: (state) => state.snackbar,
      userIntegration: (state) => state.userIntegrations,
      activeAccountId: (state) => state.activeAccount?.id,
      otpRequireDialog: (state) => state.otpRequireDialog,
    }),
    ...mapGetters('Admin', ['getListByCategory']),
    isOutlookExpired: function () {
      return this.userIntegration.some((integration) => integration.name === 'outlook' && integration.status === 'expired');
    },
  },

  watch: {
    $route (to, from) {
      if (to.query.logout || from.query.logout) {
        this.$store.dispatch(RootActions.LOG_OUT);
      }
      if (from.name === 'welcome') {
        this.toggleScreen(false);
      }
    },
    isMultipleAccountAuth: {
      handler: function (value) {
        if (value) {
          this.$router.push({ name: 'my-accounts' });
        }
      },
      immediate: true,
    },
    token: {
      handler: async function (value) {
        const parsedToken = parseJwt(value);
        this.setUserRoles(parsedToken?.scope || []);
        initSocket(value);
      },
      immediate: true,
    },
    selectedStyle: {
      handler (value) {
        if (value) {
          this.setMapStyle(value);
        }
      },
      immediate: true
    },
    defaultStyle (value) {
      if (!value) {
        this.setMapStyle(defaultMapStyle.value);
      }
    }
  },

  created: function () {
    eventBus.$on('updateAppVersionNoModal', this.updateAppVersionNoModal);
    eventBus.$on('showSignIn', this.showSignInHandler);

    this.$eventBus.$on('open-request-form', this.openRequestForm)
    this.$eventBus.$on('closeFeedbackForm', this.closeFormHandler)

    document.addEventListener(
      'sw:updated',
      this.showRefreshUI,
      { once: true }
    );

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener(
        'controllerchange', () => {
          if (this.swUpdate.refreshing) return;
          this.swUpdate.refreshing = true;
          window.location.reload();
        }
      );
    }
  },

  beforeMount () {
    if (!this.token) {
      this.toggleScreen(false);
      return;
    }
    this.fetchAllSettings();
  },

  updated: function () {
    const propertyTypes = this.getListByCategory('property_types').items;

    if (!propertyTypes.length && this.token) {
      this.list({ token: this.token });
    }
  },

  mounted: function () {
    if (this.$route.query.verify_email) {
      this.verifyEmail();
    }
  },

  beforeDestroy () {
    eventBus.$off('updateAppVersionNoModal', this.updateAppVersionNoModal);
    eventBus.$off('showSignIn', this.showSignInHandler);

    this.$eventBus.$off('closeFeedbackForm', this.closeFormHandler);
    this.$eventBus.$off('open-request-form', this.openRequestForm)
  },

  methods: {
    ...mapActions({
      fetchUser: RootActions.FETCH_CURRENT_USER,
      fetchAccountSettings: RootActions.FETCH_ACCOUNT_SETTINGS,
      fetchUserIntegrations: RootActions.FETCH_USER_INTEGRATIONS,
      fetchAccountPreferences: RootActions.FETCH_ACCOUNT_PREFERENCES,
    }),
    ...mapActions('Admin', {
      list: GET_LISTS,
    }),
    ...mapActions('Settings', {
      fetchSettings: GET_USER_SETTINGS,
    }),
    ...mapActions('Subscriptions', {
      getSubscriptions: SubscriptionsActions.GET_SUBSCRIPTIONS,
    }),
    ...mapMutations('Notifications', ['ADD_NOTIFICATION']),
    ...mapMutations({
      toggleScreen: TOGGLE_SPLASH_SCREEN,
      toggleSnackbar: TOGGLE_SNACKBAR,
      setUserRoles: RootMutations.SET_USER_ROLES
    }),
    ...mapMutations({
      updateAppVersion: RootMutations.UPDATE_APP_VERSION
    }),
    verifyEmail: async function () {
      if (!this.$route.query.verifier) {
        window.history.replaceState({}, document.title, `/#/${this.user.active_account.id}/dashboard`);
        return;
      }
      try {
        await authService.verifyEmail(this.$route.query.verifier);
        this.emailVerifyDialog = true;
      } catch (error) {
        const notification = { ...error };
        this.ADD_NOTIFICATION(notification);
      } finally {
        window.history.replaceState({}, document.title, `/#/${this.user.active_account.id}/dashboard`);
      }
    },
    openRequestForm: function () {
      this.dialog = true;
    },
    showRefreshUI (event) {
      this.swUpdate.registration = event.detail;
      this.swUpdate.snackbar = true;
    },
    refreshAppSw () {
      this.swUpdate.snackbar = false;
      if (!this.swUpdate.registration || !this.swUpdate.registration.waiting) { return; }
      this.swUpdate.registration.waiting.postMessage('skipWaiting');
      this.swUpdate.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    },
    showSignInHandler ({ preventRedirect = false }) {
      this.preventRedirect = preventRedirect;
      this.showSignIn = true;
    },
    closeSnackbarHandler: function () {
      this.toggleSnackbar({
        open: false,
        message: '',
      });
    },
    closeFormHandler () {
      this.dialog = false;
    },
    updateAppVersionNoModal (newVersion) {
      this.updateAppVersion(newVersion);
    },
    /**
     * Handler for the success event
     * */
    successEventHandler: function () {
      this.showSignIn = false
      this.toggleScreen(false)
    },
    async fetchAllSettings () {
      try {
        this.toggleScreen(true);

        if (!this.user) {
          await this.fetchUser();
        }

        const settingsPromises = [
          this.list({ token: this.token }),
          this.fetchSettings(this.userId).then(() => {
            subscribeToNotifications();
          }),
          this.fetchAccountSettings(),
          this.fetchAccountPreferences(),
          this.fetchUserIntegrations(),
          this.getSubscriptions(),
        ];

        this.$i18n.setLocaleMessage('en', getLocaleMessages(this.activeAccountId));

        await Promise.all(settingsPromises);
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.toggleScreen(false);
      }
    },
  },
};
</script>
