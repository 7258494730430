export const routesWithoutReload = [
  'member-account',
  'member-notes',
  'member-hr',
  'member-security',
  'member-company-details',
  'member-license-info',
  'member-compensation',
  'member-documents',
  'member-login',
  'dashboard',
];
